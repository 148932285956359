import { useState, useRef, useEffect } from "react";
import { Upload, Image } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { ModalComponent } from './index';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const ImageUpload = ({ name, onChange, reset }) => {
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
            return;
        }

        if (reset) {
            setFileList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset])

    const handleChange = ({ fileList: newFileList }) => {
        if (newFileList.length) {
            newFileList[newFileList.length - 1].type = name;
            setFileList(newFileList);
            onChange('file', newFileList)
        } else {
            setFileList(newFileList);
            onChange('deleteFile', name);
        }
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    }

    const handleRemove = () => {
        setShowModal(true);
        return false;
    }

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <UploadOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    return (
        <div>
            <Upload
                accept="image/png, image/jpeg"
                beforeUpload={() => { return false; }}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={handleRemove}
            >
                {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            {previewImage && (
                <Image wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
            {showModal ?
                <ModalComponent
                    width='550px'
                    open={true}
                    title={`Are you sure you want to delete ${name}`}
                    saveData={() => { handleChange({fileList: []}); setFileList([]); setShowModal(false) }}
                    onCancel={() => setShowModal(false)}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={fileList[0]?.thumbUrl} alt="" />
                    </div>
                </ModalComponent>
                : null}
        </div>
    )
}