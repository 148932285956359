import * as AuthHelper from './APIHelper';

export const GetObsAudit = () => {
    return AuthHelper.getResponse('/obsaudit');
}

export const GetObsAuditWithParams = (params) => {
    return AuthHelper.getResponse(`/obsaudit?${params}`);
}

export const PostObsAudit = (payload) => {
    return AuthHelper.postResponse('/obsaudit', payload);
}

export const PutObsAudit = (id, payload) => {
    return AuthHelper.putResponse(`/obsaudit/${id}`, payload);
}

export const DeleteObsAudit = (id) => {
    return AuthHelper.deleteResponse(`/obsaudit/${id}`);
}