import { useEffect, useRef, useState } from 'react';
import { Card } from "antd";
import { getTotalVisit } from '../../data';
import { SpinnerComponent } from "../../../../../Component/Common";

export const HumanResourceAnalysis = ({ filterData }) => {
    // const [visitData, setVisitData] = useState();
    const [loading, setLoading] = useState(true);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getAllData();
            isFirstRender.current = false
            return;
        }
        // totalVisit();
    }, [])

    const getAllData = async () => {
        // setVisitData(await getTotalVisit());
        setLoading(false);
    }

    return (
        <Card title={(<>Human Resource Analysis</>)}>
            {loading ? <SpinnerComponent /> : null}
        </Card>
    )
}