import { useEffect, useState, useRef } from "react";
import { Services } from '../../__services';
import { CONSTANT, Server, getFromLocalStorage } from '../../Utils';
import { SpinnerComponent } from "../../Component/Common";
import { processData } from './functionHandler';
import { useLocation } from "react-router-dom";
import { UserComponent } from "./User";
import { CoordinatorComponent } from "./Coordinator";
import { FilterComponent } from "./Filter";
import { Button, DatePicker } from 'antd';
import { FilterWrapper } from './style';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);


export const HomePage = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [awaitingConsult, setAwaitingConsult] = useState(undefined);
    const [visitInProgress, setVisitInProgress] = useState(undefined);
    const [visitComplete, setVisitComplete] = useState(undefined);

    const [visitNeedsUpdate, setVisitNeedsUpdate] = useState(undefined);
    const [visitUpdateComplete, setVisitUpdateComplete] = useState(undefined);
    const [visitFormComplete, setVisitFormComplete] = useState(undefined);

    const [visitMicrobiology, setVisitMicrobiology] = useState(undefined);
    const [visitMicrobiologyResult, setVisitMicrobiologyResults] = useState(undefined);
    const [visitMicrobiologyComplete, setVisitMicrobiologyComplete] = useState(undefined);
    const [reviewVisit, setReviewVisit] = useState(undefined);
    const [queryVisit, setQueryVisit] = useState(undefined);

    const [allVisits, setAllVisits] = useState(undefined);
    const [initialVisits, setInitialVisits] = useState([]);
    const [followUpVisit, setFollowUpVisits] = useState([]);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getALLVisits(location.state.user_type);
            isFirstRender.current = false
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const onClickHandler = (type, value) => {
        if (type === 'switchUser') {
            location.state.user_type = value;
            getALLVisits(location.state.user_type)
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'visitDateRange') {
            if (value[0].length && value[1].length) {
                setLoading(true);
                let startDate = new Date(value[0]).getTime();
                let endDate = new Date(value[1]).getTime() + 86400000;
                let visits = allVisits.filter(vi => vi.encounters.find(en => en.encounter_type_id === CONSTANT.ENCOUNTERADULTINITIAL && ((new Date(en.created_at).getTime() > startDate) && (endDate > new Date(en.created_at).getTime()))));
                processVisit(visits);
            } else {
                processVisit(allVisits);
            }
        }
    }

    const getALLVisits = async (type = 'user') => {
        let userInfo = await getFromLocalStorage('user');
        setLoading(true);
        let params = '';
        // if (type === 'user') {
        params += `visit_type_id=${CONSTANT.VISITFIRST},${CONSTANT.VISITFOLLOWUP}&customQuery=location,patient(person),encounters(creator),obs`;
        // } 
        // else if (type === 'coordinator') {
        //     params += `visit_type_id=${CONSTANT.VISITCOORDINATOR}&customQuery=location,encounters(creator),patient(person)`;
        // }
        if (Server.ENV.name === 'sceh') {
            params += `&location_id=${userInfo?.user?.person?.location_id}`;
        }
        Services.GetVisitWithParams(params)
            .then(async response => {
                if (response.status !== 400) {
                    setAllVisits(response.rows);
                    // setFilterVisits(response.rows);
                    processVisit(response.rows, type);
                } else {

                }
            });
    }


    const processVisit = async (data = [], type = 'user') => {
        let { awaitingConsult, visitInProgress, visitComplete,
            visitNeedsUpdate, visitUpdateComplete, visitFormComplete,
            visitMicrobiology, visitMicrobiologyResult, visitMicrobiologyComplete, reviewVisit, queryVisit } = await processData(data, type);
        setAwaitingConsult(awaitingConsult);
        setVisitInProgress(visitInProgress);
        setVisitComplete(visitComplete);

        setVisitNeedsUpdate(visitNeedsUpdate);
        setVisitUpdateComplete(visitUpdateComplete);
        setVisitFormComplete(visitFormComplete);

        setVisitMicrobiology(visitMicrobiology);
        setVisitMicrobiologyResults(visitMicrobiologyResult);
        setVisitMicrobiologyComplete(visitMicrobiologyComplete);
        setInitialVisits(data.filter(vt => vt.visit_type_id === CONSTANT.VISITFIRST));
        setFollowUpVisits(data.filter(vt => vt.visit_type_id === CONSTANT.VISITFOLLOWUP));
        setReviewVisit(reviewVisit);
        setQueryVisit(queryVisit);
        setLoading(false);
    }

    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day');
    };

    return (
        <>
            <FilterWrapper style={{ marginBottom: '10px' }}>
                <div style={{ width: '50%' }}>
                    <div style={{ fontWeight: 600 }}>Visit date filter:</div>
                    <RangePicker placement='bottomleft' disabledDate={disabledDate} onChange={(e, dateString) => onChangeHandler('visitDateRange', dateString)} />
                </div>
                <FilterComponent initalVisit={initialVisits} followUpVisit={followUpVisit} />
            </FilterWrapper>
            {loading && location.state.user_type ?
                <SpinnerComponent /> :
                <div style={{ padding: '5px' }}>

                    <div style={{ padding: '0px 0px 5px' }}>
                        <Button type={location.state.user_type === 'user' ? "primary" : ''} onClick={(e) => onClickHandler('switchUser', 'user')}>Paper form</Button>
                        <Button type={location.state.user_type === 'coordinator' ? "primary" : ''} onClick={(e) => onClickHandler('switchUser', 'coordinator')}>Microbiologic Results</Button>
                        {/* <Switch checkedChildren="Microbiologic Results" unCheckedChildren="Doctor Exam" checked={location.state.user_type === 'coordinator'} onChange={(e) => onChangeHandler('switchUser', e)} /> */}
                    </div>
                    {location.state.user_type === 'user' ?
                        <UserComponent awaitingConsult={awaitingConsult} visitInProgress={visitInProgress} visitComplete={visitComplete} reviewVisit={reviewVisit} queryVisit={queryVisit} visitNeedsUpdate={visitNeedsUpdate} visitUpdateComplete={visitUpdateComplete} visitFormComplete={visitFormComplete} /> :
                        <CoordinatorComponent visitMicrobiology={visitMicrobiology} visitMicrobiologyResult={visitMicrobiologyResult} visitMicrobiologyComplete={visitMicrobiologyComplete} />}
                </div>}
        </>
    )
}