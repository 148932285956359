import styled from "styled-components";

export const EMRWrapper = styled.div`
    display: flex;
    gap: 10%;
`;

export const AcuitySection = styled.div`
    display: flex;
    width: 100%;
`;

export const EMRSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const EMRInline = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;