import { useEffect, useMemo, useState } from 'react';
import { Services } from "../__services";
import { getFromLocalStorage, saveToLocalStorage } from "./useLocalStorage";

/**
 * Get token from storage
 * @returns promise
 */
export const getToken = async () => {
	return await getFromLocalStorage('web_token');
}

/**
 * Set token to localstorage
 * @param {*} token 
 * @returns 
 */
export const setToken = (token) => {
	return saveToLocalStorage('web_token', token)
}

/**
 * Check if active session is present
 * @returns 
 */
export const CheckSession = () => {
	return new Promise((resolve, reject) => {
		Services.CheckSession().then(response => {
			if (response && response.status !== 400 && response.status !== 401) {
				resolve(true)
			} else {
				saveToLocalStorage('user', null);
				reject();
			}
		}).catch(err => {
			reject();
		})
	})
}

/**
 * Function to calculate Age after passing date of birth
 * @param {string} birthday 
 * @returns age
 */
export const calculateAge = (birthday) => {
	const ageDifMs = Date.now() - new Date(birthday).getTime();
	const ageDate = new Date(ageDifMs);
	return Math.abs(ageDate.getUTCFullYear() - 1970);
}

/**
 * Get ip details of current user
 * @returns "{ip, contry, resign, zip}"
 */
export const getIP = async () => {
	return new Promise((resolve) => {
		Services.GetIpandDetails().then(response => {
			if (response) {
				let { ip, country_name, region_name, zip } = response;
				resolve({
					ip, country_name, region_name, zip
				})
			} else {
				resolve ({})
			}
		})
	})
}

/**
 * Get Color depending on audit raised
 * @param {string} value 
 * @returns color code
 */
export const getColor = (value = '') => {
	if (value === 'Need Correction') return 'red'
    else if (value === 'Answered') return 'yellow'
    else return '#B2BEB5'
}

/**
 * Constant Values used across Application
 */
export const CONSTANT = {
	SYSTEMADMIN: '6c0f28c5-bc8e-4860-a3db-cf55d0ae9d41',
	USER: 'ca5e61c1-38fb-4bb6-8540-f4f423d6fc32',

	NURSEROLE: '96c8a1e9-3a11-4a0d-a0d9-81aa7fb6f534',
	DOCTORROLE: 'a453f985-3c73-40b1-b5b2-5a92b65b8d9c',
	COORDINATORROLE: '6086bcc1-2cde-44d3-b2b1-5ed9d00c5397',
	
	NURSE: 'f3ba0210-c8e3-4a01-9b15-c95b8db6a91b',
	REVIEWER: 'd45d3ef7-6c50-455f-a567-8e0211eb7f83',

	VISITFIRST: 'b204531e-d28f-43e3-a9e4-43f1cc9aa1bd',
	VISITFOLLOWUP: 'f4be38d7-b156-42cf-8caf-51cadda41b2a',
	VISITCOORDINATOR: '5f6818cc-4180-41fe-8204-fb81646b228e',

	ENCOUNTERADULTINITIAL: '0484fb21-d714-4154-8898-84aea9c1d75c',
	ENCOUNTERVISITINPROGRESS: '351aa45f-ede4-4221-9368-434d1c1f63db',
	ENCOUNTERVISITCOMPLETE: '8a62ea5a-3531-4149-bb54-4a740c87767e',

	ENCOUNTERMICROBIOLOGY: 'c2216f97-a16a-4eb6-a186-c7cf4c0e9d4e',
	ENCOUNTERMICROBIOLOGYRESULTS: 'f6d98c74-65a1-4a33-a425-7d10b101a08d',
	ENCOUNTERMICROBIOLOGYCOMPLETE: '81739e9e-aebe-40cb-9fbd-94dff02a5399',

	ENCOUNTERREVIEW: '9eff21af-cd70-44b8-b322-cf8b2c0310b9',
	ENCOUNTERQUERY: '44a316f2-84c7-4f78-beb1-6f3271243129',

	COMPLAINTRIGHT: '08c18f4e-1e97-43be-a106-1b12debce911',
	COMPLAINTLEFT: '80573476-815f-412c-87de-c26f5d366c96',
	VARIGHT: '529c2adb-91d2-4916-944b-1237755a8540',
	VALEFT: 'a1ebc13a-7f43-4510-809a-135cbc1fc445',
	PINEHOLERIGHT: '096e00f0-08a7-4f22-9fc4-8341ac391825',
	PINEHOLELEFT: 'f452f28b-5578-4550-bcd7-6b2f4b06a51b',

	EMRIOPLEFT: 'ba8f4c2b-87a6-459d-ba39-a9b8597b9979',
	EMRIOPRIGHT: 'c21573fe-5e86-4262-a459-07fb79a058fd',
	EMRBLOODSUGAR: '406b0ccb-41bf-4d2d-9d39-c11bb062400e',
	EMRLACRIMALDUCTRIGHT: 'e1d74cc0-91e9-4680-bef1-80984c4c16bc',
	EMRLACRIMALDUCTLEFT: '103428c4-bb50-42ea-a8af-a8d9b4287cc2',
	EMRVAMEASUREMENTRIGHT: '3e92f848-08f7-4bff-a18e-5412636a3b12',
	EMRVAMEASUREMENTLEFT: '41a8a419-df87-4799-bc4a-8e1d274b7f4a',
	EMRREFERRAL: 'f5b73113-f6c2-44a9-91b5-c8cffeb63ac1',

	DIAGNOSISLEFTEYELENS: '9d988280-2b4a-495d-81e3-a182f1a6f814',
	DIAGNOSISRIGHTEYELENS: '6a0f4cd0-49a7-43eb-8ceb-8e44f805b386',
	DIAGNOSISLEFTEYEADDITIONALPATHALOGY: '8b940aec-1bf9-4e65-bdfe-ec495b76483b',
	DIAGNOSISRIGHTEYEADDITIONALPATHALOGY: 'cda9995c-6ad5-48a9-847d-096361e74295',
	DIAGNOSISRIGHTEYECORNEAL: 'fbf97628-1968-431b-bcab-6e31cba47f2b',
	DIAGNOSISLEFTEYECORNEAL: 'a77bcd58-9d4d-4a57-9c99-45b8a3080902',

	DIAGNOSISCOREALINFECTION: '762997c3-edf5-43c2-9efa-3385f1fe8ab5',

	DIAGNOSISSLITLAMPFINDINGEYELIDRIGHT: 'd48d97ae-f95c-4337-a82b-9c832d6bb6cb',
	DIAGNOSISSLITLAMPFINDINGEYELIDLEFT: '15491e65-fe61-49ae-b5b2-5954513a2b9c',
	DIAGNOSISSLITLAMPFINDINGCORNEARIGHT: 'fe4817c3-00f0-4c01-85d9-10cf382d7150',
	DIAGNOSISSLITLAMPFINDINGCORNEALEFT: '3fcbb1be-dddd-4339-93df-5a8b0213bf49',
	DIAGNOSISSLITLAMPFINDINGLENSRIGHT: '537b305e-ded3-43e8-8001-584b7df72b7d', //'02e94e24-9e3a-4308-9f98-0973cf122d58',
	DIAGNOSISSLITLAMPFINDINGLENSLEFT: '8e140a16-8436-4d56-8906-6dcede9ca48c',
	DIAGNOSISSLITLAMPFINDINGCONJUNCTIVARIGHT: '2e42f300-7e1b-46a1-9191-5a0bf9cfc5eb',
	DIAGNOSISSLITLAMPFINDINGCONJUNCTIVALEFT: 'ba8a4523-55ae-49df-b8a6-9d6da1f60962',
	DIAGNOSISSLITLAMPFINDINGANTERIORRIGHT: '29daa8ff-720a-40dd-b973-05085db33298',
	DIAGNOSISSLITLAMPFINDINGANTERIORLEFT: '702c799d-e8d6-4994-99b4-58c425aa9f7f',

	DIAGNOSISOTHERNOTERRIGHT: 'b80bfe48-9c0b-4234-9ee0-1863e57bdeee',
	DIAGNOSISOTHERNOTERLEFT: '35215b70-b3d6-4f84-b8f5-6b4734bf9d34',

	DIAGNOSISCATARACTTYPERIGHT: 'cf51c2c2-ad9c-42cb-9864-c419876e5db4',
	DIAGNOSISCATARACTTYPELEFT: 'e3e70ffc-1e4f-435a-8c9d-5d544d40b38f',
	DIAGNOSISCATARACTGRADERIGHT: '42bc78c0-8a86-4e37-b9d7-2bd776bf2749',
	DIAGNOSISCATARACTGRADELEFT: 'ae1df5a3-360a-4ff1-a6c3-c99fd2941585',

	INFECTIONHISTORYRIGHTSYMPTOMSDAYS: 'f61ea259-34b2-4883-8bc1-7379765c205f',
	INFECTIONHISTORYRIGHTPRESENTATIONDATE: '10c4d5ac-1592-4ae9-b6d6-25e5b338a909',
	INFECTIONHISTORYRIGHTRISKFACTOR: 'db2b3587-d476-4a27-897c-130343bc93ea',

	INFECTIONHISTORYRIGHTWORKING: '9556def4-9744-4294-8a49-53e590a0fd73',
	INFECTIONHISTORYRIGHTEPITHELIAL: '828945f0-5c39-4b6e-a7bc-53019f87ef3e',
	INFECTIONHISTORYRIGHTDIAMETER: 'bef78d9e-0087-4bd9-9134-5933a63351af',
	INFECTIONHISTORYRIGHTDEPTH: 'a61c400d-1b5a-4407-8d8a-6981f017696f',
	INFECTIONHISTORYRIGHTCENTERALLY: '5aa89857-af59-45d4-948e-9b5384d519f6',
	INFECTIONHISTORYRIGHTLIMBUS: 'fb0665de-27de-42c2-b960-7d65f541342f',
	INFECTIONHISTORYRIGHTSTROMAL: 'afd3b3a2-5706-4e00-be1b-4314794399f4',
	INFECTIONHISTORYRIGHTHYPOPYON: '2c84b147-f306-4dc8-bbe3-94c6a87c68d9',
	INFECTIONHISTORYRIGHTSPECIFIC: '10c7cb24-5140-461e-9d55-7a412976c003',
	INFECTIONHISTORYRIGHTPROCEDURE: 'fc0f0e2f-3bdc-4528-9811-14196d2d3279',
	INFECTIONHISTORYRIGHTTHERAPEUTIC: 'be385907-cae1-4402-b40c-57fc17fdacbb',
	INFECTIONHISTORYRIGHTMICROBIOLOGY: '4461f783-6677-41ef-8d66-cc94e2bd97e8',
	INFECTIONHISTORYRIGHTFOLLOWUP: '674e4738-f43b-4f68-9102-dc94e3120bd9',

	INFECTIONHISTORYLEFTSYMPTOMSDAYS: '1a7fa7e8-c21b-45b9-8c82-06b9a3f230ff',
	INFECTIONHISTORYLEFTPRESENTATIONDATE: '4d831d16-95f4-4c56-801a-84aab49e6cc3',
	INFECTIONHISTORYLEFTRISKFACTOR: '833ec3fb-9ef2-4b27-82ff-b9f19408c203',
	INFECTIONHISTORYLEFTWORKING: '4449df9b-f5a8-4cd0-8ea4-216c878543d9',
	INFECTIONHISTORYLEFTEPITHELIAL: 'a828c071-0e5f-4395-ae22-81fc115576ca',
	INFECTIONHISTORYLEFTDIAMETER: '644b3dfa-8713-42a0-902a-149055482f34',
	INFECTIONHISTORYLEFTDEPTH: '8c5db937-932e-4f02-b745-16b0e3135a45',
	INFECTIONHISTORYLEFTCENTERALLY: 'ce2fc72f-833a-4ea2-a000-ffcc40bca567',
	INFECTIONHISTORYLEFTLIMBUS: 'ecbb2f2e-f484-4a60-a7ab-82f47556e78f',
	INFECTIONHISTORYLEFTSTROMAL: 'b1f884a9-04fa-4e85-826f-f29318207ef5',
	INFECTIONHISTORYLEFTHYPOPYON: 'ebc80f32-9480-4a6d-b14f-37cfa0d6cd0c',
	INFECTIONHISTORYLEFTSPECIFIC: 'e430d670-1f69-4da5-a24c-690fdddc9380',
	INFECTIONHISTORYLEFTPROCEDURE: '56d30583-37c8-4463-aafd-a3c7d440360b',
	INFECTIONHISTORYLEFTTHERAPEUTIC: '14a2a3e2-8ad4-43f5-b158-89d599931c04',
	INFECTIONHISTORYLEFTMICROBIOLOGY: '98feb790-2669-49a8-8b47-11bb4a1cc9a9',
	INFECTIONHISTORYLEFTFOLLOWUP: '9f54359f-31ad-4b19-8d90-bda49932dd67',

	MEDICALHISTORYRIGHT: 'ff386a14-432d-403e-a13a-dffa24b5452b',
	MEDICALHISTORYLEFT: 'a02decfb-692d-490b-9190-087e19ad9ce6',

	COORDINATORKOHSTAIN: 'c3d71743-b1e9-432c-8f11-2ffcd83479b8',
	COORDINATORGRAMSTAIN: '9341b340-a4ce-49eb-887f-9b5a2f669bfd',
	COORDINATORBACTERIALCULTURE: '403cdc6b-7fb8-49bd-b91b-56ee45388de6',
	COORDINATORFUNGALCULTURE: '28056e08-5b9f-466f-b6c1-01ffd62b5cc6',
	COORDINATORACANTHAMOEBA: 'd25e4af9-1550-4f41-b403-efb7fd5647c6',
	COORDINATOROTHER: 'd00e8ce7-1a7d-4462-b7b0-d1bb0498986c',

	COMMENT: '727e4c25-6dc7-4405-92cf-90cddfb7cf22',
}

export const useOnScreen = (ref) => {
	const [isIntersecting, setIntersecting] = useState(false);

	const observer = useMemo(() => new IntersectionObserver(
		([entry]) => setIntersecting(entry.isIntersecting)
	), [])

	useEffect(() => {
		observer.observe(ref.current);
		return () => observer.disconnect();
	})

	return isIntersecting
}