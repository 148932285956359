import { Alert, AutoComplete, Button, Card, Input, Radio, notification } from "antd";
import { DeleteFilled, QuestionCircleFilled } from '@ant-design/icons'
import { useRef, useEffect, useState } from "react";
import { MedicalWrapper, MedicalInline } from './style';
import { Services } from "../../../../__services";
import { CONSTANT, getFromLocalStorage, getColor } from "../../../../Utils";
import { AuditFunction } from "../Audit/AuditFunction";


const getConcept = (side, name) => {
    if (name === 'isMedication') {
        return CONSTANT[side === 'Right' ? 'MEDICALHISTORYRIGHT' : 'MEDICALHISTORYLEFT'];
    }
}

const conceptPrescription = [
    { value: 'Moxifloxacin 0.5% drops' }, { value: 'Gatifloxacin 0.3% drops' }, { value: 'Azithromycin 1% drops' }, { value: 'Azithromycin 1% ointment' },
    { value: 'Aminogen 1% drops' }, { value: 'Tobramycin 0.3% drops' }, { value: 'Tobramycin 0.3% ointment' }, { value: 'Natamycin 5% drops' }, { value: 'Voriconazole 1% drops' }, { value: 'Amphotericin-B 0.5% drops' },
    { value: 'Fluconazole 0.3% drops' }, { value: 'Itraconazole 1% ointment' }, { value: 'Econazole 2% drops' }, { value: 'Homatropine Hydrobromide 2% drops' }, { value: 'Atropine 1% drops' }, { value: 'Iotim, Iobet 0.5% drops' },
    { value: 'Paracetamol 650 mg' }, { value: 'Diclofenac 75 mg' }, { value: 'Diamox 250mg' }
];


const newPrescription = [
    { value: 'Zymer(Gatiflox 0.3)' }, { value: 'Zymaxide(Gatiflox 0.5)' },
    { value: 'Vigamox, Moxigram(Moxiflox)' }, { value: 'Oflacin(Oflox)' }, { value: 'Eyebrex(Tobrmycine)' },
    { value: 'Natamet(Natamycine)' }, { value: 'Azithral(Azithromycine)' }, { value: 'Gatiqine-p(Gatiflox+pred)' },
    { value: 'Moxivia p(Moxifloxacine+Predinesolone)' }, { value: 'Toba-DM (Tobramycine+Dexamethasone)' },
    { value: 'Gatilox DM (Gati+dexa)' }, { value: 'Milfodex(Moxi+Dexa)' }, { value: 'Zylopred(Gati+lote)' },
    { value: 'Moxisruge L (Moxi+Lote)' }, { value: 'Tobalflam(Toba+lote)' }, { value: 'FLM-Neo(Fluromethe+Neomycine)' },
    { value: 'FLM-T(Fluromethe+Tobra)' }, { value: 'Predfort/Predmet(Prednisolone)' }, { value: 'Flurisone(Fluromethe)' },
    { value: 'Loteflam(Loteprednole)' }, { value: 'Enpred(Diflurprednet)' }, { value: 'Glucomal, Timolet(Timolol 0.5%)' },
    { value: 'Timolet OD(Timolol 0.5%)' }, { value: 'Raiki(netarsudil 0.02%)' }, { value: 'Lumigain 0.15, Lumigain 0.3 % (Bimato)' },
    { value: 'Careprost plus(BImato + timo)' }, { value: 'AlphaganZ, BirimosunLS(Brimo)' }, { value: 'Brimolo, Brimotas T(Brimo + Timo)' },
    { value: 'Dorzox, Dortas(Dorzola)' }, { value: 'Misopt, Dortas T, Dorzox T(Dorzola + Timo)' }, { value: 'Azopt, Brinalr(Brinzola)' },
    { value: 'Azarga, Brinzotim(Brin + Timo)' }, { value: '9 PM, LaprostRT(latono)' }, { value: 'LatocomCF(latono + Timo)' },
    { value: 'Travatan, Travo Z, Zytrav(Travoprost)' }, { value: 'Lupitros TZ(Travo + Timo)' },
    { value: 'Synca(Brinzolamide + Brinzolamidine)' }, { value: 'Tear plus(Poly.Alcho + povi.lodine)' },
    { value: 'Normotear(Propy.glyco + poly.glyco)' }, { value: 'UV lube(chloroquine Phosphate0 .03 % )' },
    { value: 'Genteal, Tear Natural(HPMC) 0.3 % +Dextran + glyce)' }, { value: 'Optive, osmodrops(CMC 0.5 % +n - Acetyle + glyce)' },
    { value: 'Refresh Tear, Lubrex, Tear drop 9 CMC 0.5 % +Oxychlore)' }, { value: 'Refresh liquigel 1 %' },
    { value: 'Tear drop gel, (Carboxy methe.cell)' }, { value: 'Gentel, Eyemist, Zyaqua forte(HPMC 0.3 % )' },
    { value: 'Eyemist Gel (HPMC + Sodium Parb) Soha(Sodium Hyluronate 0.1%)' }, { value: 'Soha Gel (Sodium Hyluronate 0.18%)' },
    { value: 'Systane Ultra(ProplyneGlycol+polythelene glycol)' }, { value: 'Systane Hydration(PG+PEG+HPgaur+sodium Hyluronate)' },
    { value: 'Dilate (cyclopen)' }, { value: 'Optimide plus(Tropic+phenyl)' }, { value: 'Homide(Homatropine)' },
    { value: 'Kidtro (Atropine)' }, { value: 'Ocurest plus, Ocurest AH (NAPHA+CAM+ZIN+BORI+SODI+PHENYLEFRINE+HPMC)' },
    { value: 'Winolap, Winolap DX, Winolap Max(Olapatadine)' }, { value: 'Debfit(Bepotastine 1.5%)' },
    { value: 'Acupat(ketro+olapat)' }, { value: 'Accular-Ls(ketorolac)' }, { value: 'FBN,Flur(flurbi)' },
    { value: 'Nepcinac(neoaqfenac)' }, { value: 'Megbrom(Bromofenac)' }, { value: 'Restasis, Cyclomune(cyclosporine)' },
    { value: 'Ketoflox(Oflox+Ketrolac)' }, { value: 'Ecotearwipes10’s(TT Oil+Aloevera+PEG40+chamomile+glycrine)' },
    { value: 'ZaquaCleanser (Eye lid Cleanser)' }, { value: 'Azithral (Azythromycine)' }, { value: 'Omeflox(Ofloxa)' },
    { value: 'Zymer(Gatiflo)' }, { value: 'Moxigram( Moxiflox)' }, { value: 'Tobracid(Tobramycinea)' },
    { value: 'E FLO(Fluromethe)' }, { value: 'Isol-6(Sodium Chloride) ' }, { value: 'Genetal gel(HPMC)' },
    { value: 'Acivir(Aciclovir)' }, { value: 'Gancigel(Ganciclovir)' }, { value: 'Cap. I Site AF, Fit Eye, I Site Plus (Lutein+ Zexanthine)' },
    { value: 'Tab.Gloeye (Bilberry Extractt 80 MG+Pine Bark Extract 25 MG)' }, { value: 'Cap. Osmega (Omega 3Fatty Acid)' },
    { value: 'Tab. Ciprofloxacin 250mg/500mg' }, { value: 'Cap. Ampi+Cloxa 250 mg/500mg' }, { value: 'Tab. Ofloxacin 200mg/400mg' },
    { value: 'Tab. Ibu+Para / Diclofenac+Serratio' }, { value: 'Tab. Ranetidine 150 mg/Tab. Pantoprazole 20mg/40mg' },
    { value: 'Tab. Diamox (Acetazolamide) 250 mg/500mg' }, { value: 'Tab. Arovit/Neuroblon/Limcee 500 mg (Vitamin C))' },
    { value: 'Tab. Allercet (Cetrizine 10)' }, { value: 'Tab. Prednisolone 5mg/10mg/20mg' }, { value: 'Tab. Calcium' }
]


const frequenceyList = [{ value: "Hourly" }, { value: "Once daily" }, { value: "Twice daily" }, { value: "Three times daily" }, { value: "Four times daily" }, { value: "Every 2 hours" }, { value: "Every 4 hours" }, { value: "Every 8 hours" }, { value: "Every other day" }];

export const MedicalHistory = ({ data, visitComplete, name, onChange, audit }) => {
    const [userInfo, setUserInfo] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [allMedical, setAllMedical] = useState([]);
    const [days, setDays] = useState('');
    const [preDays, setPreDays] = useState('');
    const [eye, setEye] = useState('');
    const [isMedication, setIsMedication] = useState('');
    const [medicalHistoryAudit, setMedicalHistoryAudit] = useState([]);

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getMedicalHistory();
            getUser();
            isFirstRender.current = false
            return;
        }

        if (audit) {
            setMedicalHistoryAudit(audit.medicalHistoryAudit);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audit])

    const getUser = async () => {
        let user = await getFromLocalStorage('user');
        setUserInfo(user);
    }

    const onClickHandler = (type, value, path) => {
        if (type === 'deleteMedical') {
            deleteMedical(value, path);
        } else if (type === 'addMedication') {
            submitMedicalHistory()
        } else if (type === 'audit') {
            onChange('medicalHistoryAudit', value, path);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'isMedication') {
            setIsMedication(value);
            if (!value) {
                submitMedicalHistory();
            }
        }
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const getMedicalHistory = async () => {
        setAllMedical(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'MEDICALHISTORYRIGHT' : 'MEDICALHISTORYLEFT']) || []);
    }

    const submitMedicalHistory = async () => {
        try {
            let allData = [];
            let payload = {
                encounter_id: data.id,
                creator_id: userInfo.user.person_id,
                patient_id: data.patient_id,
                visit_id: data.visit_id,
            }
            if ((preDays && eye) || !isMedication) {
                if (allMedical.length) {
                    let id = allMedical[0]?.obs_string?.id
                    let putPayload = {
                        obs_id: allMedical[0]?.id,
                        value: !isMedication ? 'No Medication' : `${preDays} - ${eye} Eye`
                    }
                    let auditPayload = {
                        ...payload,
                        concept_id: CONSTANT[name === 'Right' ? 'MEDICALHISTORYRIGHT' : 'MEDICALHISTORYLEFT'],
                        status: 'Answered',
                        value: allMedical[0]?.obs_string?.value
                    }
                    editMedicalHistory(id, putPayload, auditPayload);
                    return;
                }
                allData.push({
                    ...payload,
                    concept_id: CONSTANT[name === 'Right' ? 'MEDICALHISTORYRIGHT' : 'MEDICALHISTORYLEFT'],
                    obs_string: {
                        value: !isMedication ? 'No Medication' : `${preDays} - ${eye} Eye`
                    }
                })
            }
            console.log(allData)
            if (allData.length) {
                Services.PostObs(allData).then(response => {
                    if (response.status !== 400) {
                        setDiagnosisValue(response);
                        resetField();
                        openNotificationWithIcon('success', 'Medical History Submitted', '');
                    }
                })
            }
        } catch (e) {
            console.log('Submit Medical History Exception: ', e);
        }
    }

    const setDiagnosisValue = (response = []) => {
        try {
            setAllMedical(response)
        } catch (e) {
            console.log('Set Diagnosis Exception: ', e);
        }
    }

    const editMedicalHistory = async (id, putValue, auditValue) => {
        try {
            await AuditFunction.postAudit(auditValue);
            Services.PutObsString(id, putValue).then(response => {
                response = {
                    ...auditValue,
                    id,
                    obs_string: {
                        value: putValue?.value
                    }
                }
                setDiagnosisValue([response]);
                onChange('fetchAudit');
            })
        } catch (err) {
            console.log('Edit Exception :', err);
        }
    }

    const deleteMedical = (id, path) => {
        try {
            Services.DeleteObs(id).then(response => {
                if (response.status !== 400 && response.affected) {
                    if (path === 'medicine') {
                        let index = allMedical.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            let newData = [...allMedical]
                            newData.splice(index, 1);
                            setAllMedical(newData);
                            openNotificationWithIcon('success', 'Medical History Deleted');
                        }
                    }
                } else {
                    openNotificationWithIcon('error', 'Server Error')
                }
            })
        } catch (e) {
            console.log('Delete Medical History Exception: ', e);
        }
    }

    const resetField = () => {
        setDays('');
        setPreDays('');
        setEye('');
    }

    return (
        <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <Card title={(<>Medical History {name}</>)} style={visitComplete && userInfo?.user?.person_type_id !== CONSTANT.REVIEWER ? { pointerEvents: "none", opacity: "0.4", height: '100%' } : { height: '100%' }}>
                <MedicalInline>
                    <QuestionCircleFilled style={{ color: getColor(medicalHistoryAudit?.filter(vr => getConcept(name, 'isMedication') === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', getConcept(name, 'isMedication'), `Medical History ${name}`)} />
                    <div>Was medication prescribed at this visit?</div>
                </MedicalInline>
                <Radio.Group value={isMedication} onChange={(e) => onChangeHandler('isMedication', e.target.value)}>
                    {[{ label: 'Yes', value: true }, { label: 'No', value: false }]?.map((dia, index) => <Radio key={index} value={dia.value}>{dia.label}</Radio>)}
                </Radio.Group>
                {isMedication ? <MedicalWrapper>
                    <div>
                        <div style={{ marginLeft: 10 }}>Topical Steroid Used:</div>
                        <AutoComplete options={newPrescription}
                            style={{ width: 200, marginLeft: 10 }}
                            value={preDays} onChange={(e) => setPreDays(e)}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                        </AutoComplete>
                    </div>
                    {/* <div>
                        <div>Number of days used: </div>
                        <Input type="number" min="1" placeholder="Days" value={days} onChange={(e) => setDays(e.target.value)} />
                    </div> */}
                    <div>
                        <div style={{ marginLeft: 10 }}>Frequency:</div>
                        <AutoComplete options={frequenceyList}
                            style={{ width: 200, marginLeft: 10 }}
                            value={eye} onChange={(e) => setEye(e)}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                        </AutoComplete>
                    </div>
                    <Button type="primary" onClick={() => onClickHandler('addMedication')}>Add Med</Button>
                </MedicalWrapper> : null}
                {allMedical?.map(medical => <div key={medical.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{medical.obs_string.value}</div>
                            <DeleteFilled onClick={() => onClickHandler('deleteMedical', medical.id, 'medicine')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
            </Card>
            {contextHolder}
        </div>
    )
}