import { Button, Badge, Layout, Menu } from 'antd';
import { NavigationWrapper } from './style';
import { useNavigate, useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import { Context } from "../../Pages/ContextProvider";
import { UploadImage } from '../../Pages/UploadImage';
import { PatientSearch } from '../../Pages/PatientSearch';

const { Header, Content, Footer } = Layout;
const items = [
    {
        label: 'Data entry',
        key: 'home',
    },
    {
        label: 'Patient Search',
        key: 'search'
    },
    {
        label: 'Upload Images',
        key: 'image'
    },
    {
        label: 'Admin',
        key: 'admin'
    },
    
]


export const LayoutPage = ({ children }) => {
    const { state: { page }, updateState } = useContext(Context);
    const [showImageUpload, setShowImageUpload] = useState(false);
    const [showPatientSearch, setShowPatientSearch] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const onClickHandler = (type, value) => {
        if (type === 'logout') {
            updateState('user', null)
            navigate('/')
        } else if (type === 'menu') {
            if (value.key === 'home') {
                updateState('page', 'user');
                navigate('/home', { state: location.state });
            } else if (value.key === 'admin') {
                updateState('page', '/admin');
                navigate('/admin/dashboard', { state: location.state })
            } else if (value.key === 'image') {
                setShowImageUpload(true);
            }  else if (value.key === 'search') {
                setShowPatientSearch(true);
            } else {
                console.log(value);
            }
        } else if (type === 'showPatientSearch') {
            setShowPatientSearch(value);
        } else if (type === 'showImageUpload') {
            setShowImageUpload(false);
        } else if (type === 'openBadge') {
            console.log(type)
        }
    }


    return (
        <> 
            <Layout style={{ minHeight: "100vh" }}>
                <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
                    <NavigationWrapper>
                        <div id="nav-bar" style={{ display: 'flex', alignItems: 'center', gap: '3%' }}>
                            <Menu theme="dark" mode="horizontal" style={{ width: window.location.href.match('/visitsummary') ? '530px' : '445px' }} items={window.location.href.match('/visitsummary') ? [{label: <Badge id="audit-badge" count={1} offset={[10, -11]} style={{zIndex: 9999999}} onClick={() => onClickHandler('openBadge')}><div style={{color: 'rgba(255, 255, 255, 0.65)'}}>Queries</div></Badge>, key: 'queries'}, ...items ]: items} onClick={(e) => onClickHandler('menu', e)} />
                            <Button type="primary" danger onClick={() => onClickHandler('logout')}>Logout</Button>
                        </div>
                    </NavigationWrapper>
                </Header>
                <Content>
                    <div style={{ padding: page !== '/admin' ? '24px 100px' : '' }}>
                        {children}
                    </div>
                </Content>
                {page !== '/admin' ?
                    <Footer style={{ textAlign: 'center', position: 'sticky', bottom: '0', padding: '10px' }}>Visilant ©2023</Footer>
                    : null}
            </Layout>
            {showImageUpload ? <UploadImage onClick={onClickHandler}/> : null}
            {showPatientSearch ? <PatientSearch onClick={onClickHandler} /> : null}
        </>
    )
}