import { useEffect, useRef, useState } from 'react';
import { Collapse, theme, Card } from 'antd';
import { TableComponent } from '../../../Component/Common';
import { CaretRightOutlined } from '@ant-design/icons';
import { BadgeWrapper, CardData, Badge } from './style';
import { CONSTANT, getFromLocalStorage } from '../../../Utils';

const { Panel } = Collapse;



export const UserComponent = ({ awaitingConsult, visitInProgress, visitComplete, reviewVisit, queryVisit, visitNeedsUpdate, visitUpdateComplete, visitFormComplete }) => {
    const [userInfo, setUserInfo] = useState();
    const isFirstRender = useRef(true);
    const { token } = theme.useToken();
    const panelStyle = {
        marginBottom: 24,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: 'none'
    };

    useEffect(() => {
        if (isFirstRender.current) {
            getUser();
            isFirstRender.current = false
            return;
        }
    }, [])

    const getUser = async () => {
        let user = await getFromLocalStorage('user');
        setUserInfo(user);
    }

    return (
        <>
            {/* <BadgeWrapper>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Paper Form Entry Not Started</div>
                        <Badge color='#faad14'>{awaitingConsult.length}</Badge>
                    </CardData>
                </Card>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Paper Form Entry Partially Complete</div>
                        <Badge color='#0096FF'>{visitInProgress.length}</Badge>
                    </CardData>
                </Card>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Paper Form Entry Complete</div>
                        <Badge color='green'>{visitComplete.length}</Badge>
                    </CardData>
                </Card>
            </BadgeWrapper> */}
            {/* <Collapse
                bordered={false}
                size='large'
                defaultActiveKey={['1', '2', '3', '4', '5']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            >
                <Panel header={(<div>
                    <div style={{ fontSize: 20 }}>Paper Form Entry Not Complete</div>
                    <div style={{ fontSize: 14 }}>Total: {awaitingConsult?.length + visitInProgress?.length}</div>
                </div>
                )} key="1" style={panelStyle}>
                    {awaitingConsult?.length ?
                        <TableComponent data={[...awaitingConsult, ...visitInProgress]} color='#46B1E1' /> :
                        <p>No Data</p>
                    }
                </Panel>
                <Panel header={(<div>
                    <div style={{ fontSize: 20 }}>Paper Form Entry Needs Review</div>
                    <div style={{ fontSize: 14 }}>Total: {visitComplete?.length}</div>
                </div>
                )} key="2" style={panelStyle}>
                    {visitInProgress?.length ?
                        <TableComponent data={visitComplete} color='#C00000' /> :
                        <p>No Data</p>
                    }
                </Panel>



                <Panel header={(<div>
                    <div style={{ fontSize: 20 }}>Paper Form Entry Needs Updates</div>
                    <div style={{ fontSize: 14 }}>Total: {visitNeedsUpdate?.length}</div>
                </div>
                )} key="3" style={panelStyle}>
                    {visitInProgress?.length ?
                        <TableComponent data={visitNeedsUpdate} color='#F9C74F'/> :
                        <p>No Data</p>
                    }
                </Panel>
                <Panel header={(<div>
                    <div style={{ fontSize: 20 }}>Paper Form Update Complete, Needs Additional Review</div>
                    <div style={{ fontSize: 14 }}>Total: {visitUpdateComplete?.length}</div>
                </div>
                )} key="4" style={panelStyle}>
                    {visitInProgress?.length ?
                        <TableComponent data={visitUpdateComplete} color='#C75072' /> :
                        <p>No Data</p>
                    }
                </Panel>
                <Panel header={(<div>
                    <div style={{ fontSize: 20 }}>Paper Form Entry Complete</div>
                    <div style={{ fontSize: 14 }}>Total: {visitFormComplete?.length}</div>
                </div>
                )} key="5" style={panelStyle}>
                    {visitComplete?.length ?
                        <TableComponent data={visitFormComplete} color='#43AA8B' /> :
                        <p>No Data</p>
                    }
                </Panel>
            </Collapse> */}

            <Collapse bordered={false}
                size='large'
                defaultActiveKey={['1', '2', '3', '4', '5']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} >
                <Panel header={(<div>
                    <div style={{ fontSize: 20 }}>Paper Form Entry Not Complete</div>
                    <div style={{ fontSize: 14 }}>Total: {awaitingConsult?.length + visitInProgress?.length}</div>
                </div>
                )} key="1" style={panelStyle}>
                    {awaitingConsult?.length ?
                        <TableComponent data={[...visitInProgress, ...awaitingConsult]} color='#46B1E1' /> :
                        <p>No Data</p>
                    }
                </Panel>
                {[CONSTANT.REVIEWER].includes(userInfo?.user?.person_type_id) ? <Panel header={(<div>
                    <div style={{ fontSize: 20 }}>Paper Form Entry Needs Review</div>
                    <div style={{ fontSize: 14 }}>Total: {reviewVisit?.length}</div>
                </div>
                )} key="3" style={panelStyle}>
                    {reviewVisit?.length ?
                        <TableComponent data={reviewVisit} color='#F9C74F' /> :
                        <p>No Data</p>
                    }
                </Panel> : null}
                <Panel header={(<div>
                    <div style={{ fontSize: 20 }}>Paper Form Entry Needs Update Table</div>
                    <div style={{ fontSize: 14 }}>Total: {queryVisit?.length}</div>
                </div>
                )} key="4" style={panelStyle}>
                    {queryVisit?.length ?
                        <TableComponent data={queryVisit} color='#C75072' /> :
                        <p>No Data</p>
                    }
                </Panel>
                <Panel header={(<div>
                    <div style={{ fontSize: 20 }}>Paper Form Entry Complete</div>
                    <div style={{ fontSize: 14 }}>Total: {visitComplete?.length}</div>
                </div>
                )} key="5" style={panelStyle}>
                    {visitComplete?.length ?
                        <TableComponent data={visitComplete} color='#43AA8B' /> :
                        <p>No Data</p>
                    }
                </Panel>
            </Collapse>
        </>
    )
}