import styled from "styled-components";

export const MedicalWrapper = styled.div`
    display: flex;
    justify-content: start;
    gap: 4%;
    align-items: end;
`;

export const MedicalInline = styled.div`
    display: flex;
    gap: 8px;
`;