import { useState, useEffect, useRef } from "react";
import { Button, Input, Select, Space, Dropdown } from 'antd';
import { BotWrapper, BotHeader, BotBody, BotInput, CommentWrapper, CommentList } from './style';
import { DashOutlined } from '@ant-design/icons';
import { CONSTANT, getFromLocalStorage } from '../../Utils';

const { Option } = Select;

let option = [
    { value: 'Need Correction', label: 'Need Correction' },
    { value: 'Answered', label: 'Answered' },
    { value: 'Closed', label: 'Closed' }
]

const items = [
    {
        label: <div style={{ fontSize: '10px' }}>Edit</div>,
        key: 1,
    },
    {
        label: <div style={{ fontSize: '10px' }}>Delete</div>,
        key: 2,
    }
];

export const AuditBot = ({ title, allAudit, auditValue, onChange, onClick }) => {
    const [userInfo, setUserInfo] = useState();
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getUser();
            isFirstRender.current = false
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUser = async () => {
        let user = await getFromLocalStorage('user');
        setUserInfo(user);
    }

    const onClickHandler = (type, value, additionInfo) => {
        if (type === 'option') {
            if (value.key === '1') {
                onClick('auditEdit', additionInfo)
            } else if (value.key === '2') {
                console.log(additionInfo)
                onClick('auditDelete', additionInfo)
            }
        } else if (type === 'close-bot') {
            onClick('close-bot');
        }
    }

    const checkUser = (name) => {
        let user = getFromLocalStorage('user');
        return name === `${user.user.person.first_name} ${user.user.person.last_name}`;
    }

    return (

        <BotWrapper>
            <BotHeader>
                <div>{title}</div>
                <div style={{ cursor: 'pointer' }} onClick={() => onClickHandler('close-bot')}>X</div>
            </BotHeader>
            <BotBody>
                {allAudit.map((ct, index) =>
                    <CommentWrapper key={index}>
                        <CommentList>
                            <div><b>{ct.name}</b></div>
                            <div><i>Status:</i> <b style={{ background: option.find(op => op.value === ct?.audit?.status) }}>{ct?.audit?.status}</b></div>
                            <div><i>Comment:</i> {ct?.audit?.value}</div>
                        </CommentList>
                        {checkUser(ct.name) ? <Dropdown menu={{ items, onClick: (e) => onClickHandler('option', e, ct) }} style={{ fontSize: '10px' }}>
                            <a href=';' onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <DashOutlined />
                                </Space>
                            </a>
                        </Dropdown> : null}
                    </CommentWrapper>
                )}
            </BotBody>
            <BotInput>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                    <Select
                        placeholder="Status"
                        placement='topRight'
                        value={auditValue.status}
                        style={{ width: '100%' }}
                        onChange={(value) => onChange('auditValue', value, 'status')}>
                        {option.map((t, index) => <Option key={index} value={t.value} disabled={userInfo?.user?.person_type_id !== CONSTANT.REVIEWER && ['Need Correction', 'Closed'].includes(t.label)} style={{ margin: '2px', fontSize: '11px' }}>{t.label}</Option>)}
                    </Select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Input placeholder='Type your comment here' style={{ width: '74%' }} value={auditValue.value} onChange={(e) => onChange('auditValue', e.target.value)} />
                    <Button type="primary" disabled={!auditValue.status} onClick={() => onClick('submitComment')}>Send</Button>
                </div>
            </BotInput>
        </BotWrapper>
    )
}