import * as AuthHelper from './APIHelper';

export const GetObs = () => {
    return AuthHelper.getResponse('/obs');
}

export const GetObsWithParams = (params) => {
    return AuthHelper.getResponse(`/obs?${params}`);
}

export const PostObs = (payload) => {
    return AuthHelper.postResponse('/obs', payload);
}

export const PutObs = (id, payload) => {
    return AuthHelper.putResponse(`/obs/${id}`, payload);
}

export const DeleteObs = (id) => {
    return AuthHelper.deleteResponse(`/obs/${id}`);
}

export const PutObsString = (id, payload) => {
    return AuthHelper.putResponse(`/obsstring/${id}`, payload);
}

export const PutObsObject = (id, payload) => {
    return AuthHelper.putResponse(`/obsobject/${id}`, payload);
}