import { Services } from "../../../../__services";
import { CONSTANT } from "../../../../Utils";

let EMRConcepts = [
    CONSTANT.VARIGHT, CONSTANT.VALEFT, CONSTANT.PINEHOLERIGHT, CONSTANT.PINEHOLELEFT,
    CONSTANT.EMRBLOODSUGAR, CONSTANT.EMRIOPLEFT, CONSTANT.EMRIOPRIGHT,
    CONSTANT.EMRLACRIMALDUCTLEFT, CONSTANT.EMRLACRIMALDUCTRIGHT,
    CONSTANT.EMRVAMEASUREMENTRIGHT, CONSTANT.EMRVAMEASUREMENTLEFT,
    CONSTANT.EMRBLOODSUGAR, CONSTANT.EMRREFERRAL
]

let diagnosisConcepts = [
    CONSTANT.DIAGNOSISRIGHTEYELENS, CONSTANT.DIAGNOSISLEFTEYELENS,
    CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY, CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY,
    CONSTANT.DIAGNOSISRIGHTEYECORNEAL, CONSTANT.DIAGNOSISLEFTEYECORNEAL,
    CONSTANT.DIAGNOSISCOREALINFECTION
];

let infectionHistoryConcpets = [
    CONSTANT.INFECTIONHISTORYRIGHTSYMPTOMSDAYS, CONSTANT.INFECTIONHISTORYLEFTSYMPTOMSDAYS,
    CONSTANT.INFECTIONHISTORYRIGHTPRESENTATIONDATE, CONSTANT.INFECTIONHISTORYLEFTPRESENTATIONDATE,
    CONSTANT.INFECTIONHISTORYRIGHTRISKFACTOR, CONSTANT.INFECTIONHISTORYLEFTRISKFACTOR,
    CONSTANT.INFECTIONHISTORYRIGHTWORKING, CONSTANT.INFECTIONHISTORYLEFTWORKING,
    CONSTANT.INFECTIONHISTORYRIGHTEPITHELIAL, CONSTANT.INFECTIONHISTORYLEFTEPITHELIAL,
    CONSTANT.INFECTIONHISTORYRIGHTDIAMETER, CONSTANT.INFECTIONHISTORYLEFTDIAMETER,
    CONSTANT.INFECTIONHISTORYRIGHTDEPTH, CONSTANT.INFECTIONHISTORYLEFTDEPTH,
    CONSTANT.INFECTIONHISTORYRIGHTCENTERALLY, CONSTANT.INFECTIONHISTORYLEFTCENTERALLY,
    CONSTANT.INFECTIONHISTORYRIGHTLIMBUS, CONSTANT.INFECTIONHISTORYLEFTLIMBUS,
    CONSTANT.INFECTIONHISTORYRIGHTSTROMAL, CONSTANT.INFECTIONHISTORYLEFTSTROMAL,
    CONSTANT.INFECTIONHISTORYRIGHTHYPOPYON, CONSTANT.INFECTIONHISTORYLEFTHYPOPYON,
    CONSTANT.INFECTIONHISTORYRIGHTSPECIFIC, CONSTANT.INFECTIONHISTORYLEFTSPECIFIC,
    CONSTANT.INFECTIONHISTORYRIGHTPROCEDURE, CONSTANT.INFECTIONHISTORYLEFTPROCEDURE,
    CONSTANT.INFECTIONHISTORYRIGHTTHERAPEUTIC, CONSTANT.INFECTIONHISTORYLEFTTHERAPEUTIC,
    CONSTANT.INFECTIONHISTORYRIGHTMICROBIOLOGY, CONSTANT.INFECTIONHISTORYLEFTMICROBIOLOGY
];

let followUpConcepts = [
    CONSTANT.INFECTIONHISTORYRIGHTFOLLOWUP, CONSTANT.INFECTIONHISTORYLEFTFOLLOWUP
]

let medicalHistoryConcepts = [
    CONSTANT.MEDICALHISTORYRIGHT, CONSTANT.MEDICALHISTORYLEFT
]

let allAudit = {
    EMRAudit: [],
    diagnosisAudit: [],
    infectionHistoryAudit: [],
    followUpAudit: [],
    medicalHistoryAudit: [],
    allUnique: {}
}

const getAllAudits = async (visitInProgress) => {
    try {
        let { id, visit_id, patient_id } = visitInProgress;
        let params = `visit_id=${visit_id}&patient_id=${patient_id}&encounter_id=${id}&customQuery=person`;
        let response = await Services.GetObsAuditWithParams(params);
        if (response) {
            response.rows.sort(function (a, b) {
                return new Date(a.created_at) - new Date(b.created_at);
            });
            allAudit.EMRAudit = response.rows.filter(audit => EMRConcepts.includes(audit.concept_id));
            allAudit.diagnosisAudit = response.rows.filter(audit => diagnosisConcepts.includes(audit.concept_id));
            allAudit.infectionHistoryAudit = response.rows.filter(audit => infectionHistoryConcpets.includes(audit.concept_id));
            allAudit.followUpAudit = response.rows.filter(audit => followUpConcepts.includes(audit.concept_id));
            allAudit.medicalHistoryAudit = response.rows.filter(audit => medicalHistoryConcepts.includes(audit.concept_id));
            allAudit.allUnique = getUniqueConcepts();
        }
        
        return { ...allAudit }
    } catch (err) {
        console.log('get all audit exception :', err);
    }
}

const getUniqueConcepts = () => {
    let { EMRAudit, diagnosisAudit, infectionHistoryAudit, followUpAudit, medicalHistoryAudit } = allAudit;
    let EMRAuditUnique = [...new Map(EMRAudit.map(item => [item.concept_id, item])).values()];
    let diagnosisAuditUnique = [...new Map(diagnosisAudit.map(item => [item.concept_id, item])).values()];
    let infectionHistoryAuditUnique = [...new Map(infectionHistoryAudit.map(item => [item.concept_id, item])).values()];
    let followUpAuditUnique = [...new Map(followUpAudit.map(item => [item.concept_id, item])).values()];
    let medicalHistoryAuditUnique = [...new Map(medicalHistoryAudit.map(item => [item.concept_id, item])).values()];
    let totalCount = EMRAuditUnique.length + diagnosisAuditUnique.length + infectionHistoryAuditUnique.length + followUpAuditUnique.length + medicalHistoryAuditUnique.length;
    return { EMRAuditUnique, diagnosisAuditUnique, infectionHistoryAuditUnique, followUpAuditUnique, medicalHistoryAuditUnique, totalCount }
}

const filterAudit = async (type, concept = '', name = '') => {
    try {
        return allAudit[type].filter(audit => concept === audit.concept_id).map((audit) => {
            return {
                id: audit?.id,
                audit: {
                    id: audit.id,
                    status: audit.status,
                    value: audit.value
                },
                name: `${audit.person.first_name} ${audit.person.last_name}`
            };
        })
    } catch (e) {
        console.log('Get Audit Exception: ', e);
    }
}

const postAudit = async (payload) => {
    try {
        await Services.PostObsAudit(payload);
        await getAllAudits();
        return;
    } catch (err) {
        console.log('Post Audit Exception :', err);
    }
}


export const AuditFunction = {
    getAllAudits,
    filterAudit,
    postAudit,
    getUniqueConcepts,
    allAudit
}   